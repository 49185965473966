import styled from "styled-components"
import { rem } from "polished"
import { Link as _Link } from "gatsby"

// TODO: Add prop types

const Link = styled(_Link)`
  font-size: ${rem("16px")};
  font-weight: 500;
  color: ${props => props.theme.color.link};
  text-decoration: none;
`

export default Link
