import styled, { css } from "styled-components"
import { rem, hideVisually } from "polished"
import loadable from "@loadable/component"

import { Size } from "./main-nav"
import _Link from "./links/link"
import Button from "./buttons/button"
import cometariLogo from "../images/logos/cometari/logo.svg"

export { Turn as Hamburger } from "hamburger-react"

export const ScrollSpyNav = loadable(() => import("react-scrollspy-nav"))

const _open = css`
  max-height: 200vh;
  transform: scale(1);
  opacity: 1;
  transition: max-height 0.15s ease-in, opacity 0.15s ease-in;
`
const _collapsed = css`
  position: absolute;
  max-height: 0;
  transform: scale(0);
  transform-origin: 0;
  opacity: 0;
  transition: max-height 0.15s ease-out, opacity 0.15s ease-out;
  will-change: max-height, opacity, transform;
`

export const Wrapper = styled.div`
  grid-column: full;

  &,
  > [data-nav="list"] {
    display: inherit;
    grid-template-columns: inherit;
    column-gap: inherit;
  }

  > [data-nav="list"] {
    grid-column: full;
  }
`
export const Nav = styled.nav`
  grid-column: main;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`
export const Heading = styled.h3`
  ${hideVisually()}
`
export const LogoLink = styled(_Link)``
export const Logo = styled.img.attrs({ src: cometariLogo, alt: "Cometari" })`
  user-select: none;
`
export const MenuItem = styled.li``
export const Menu = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  ${props =>
    props.breakpoint === Size.SMALL &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-top: ${rem("30px")};

      ${MenuItem} + ${MenuItem} {
        padding-top: ${rem("30px")};
      }

      ${props.isOpen ? _open : _collapsed}
    `}

  ${props =>
    props.breakpoint !== Size.SMALL &&
    css`
      ${MenuItem} {
        display: inline-block;
      }

      ${MenuItem} + ${MenuItem} {
        padding-left: ${rem("30px")};
      }
    `}
`
export const Link = styled(_Link)`
  color: ${props => props.theme.color.text};

  &.is-active {
    color: ${props => props.theme.color.primary};
  }
`
export const ContactLink = styled(Button).attrs({
  as: "a",
  variant: "secondary",
  children: "contact us",
})`
  &:hover {
    transform: none !important;
  }

  ${props =>
    props.breakpoint === Size.SMALL &&
    css`
      margin: ${rem("30px")} auto 0;

      ${props.isOpen ? _open : _collapsed}
    `}
`
export const HamburgerWrapper = styled.div`
  margin-left: auto;

  ${props =>
    props.breakpoint !== Size.SMALL &&
    css`
      display: none;
    `}
`
