import styled from "styled-components"
import PropTypes from "prop-types"

import { cardBorders, shadow } from "../../utils/styles"

const propTypes = {
  children: PropTypes.node.isRequired,
  shadowColor: PropTypes.string,
  color: PropTypes.string,
  cardBorderSize: PropTypes.number,
}

const Card = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !["color"].includes(prop) && defaultValidatorFn(prop),
})`
  ${props => cardBorders(props.cardBorderSize ?? 30)}
  ${shadow(props => props.shadowColor ?? props.theme.color.shadow)}

  padding: 1px;
  background-color: ${props => props.color};
`

Card.propTypes = propTypes

export default Card
