import styled, { css } from "styled-components"
import { rem, hideVisually } from "polished"
import { images } from "../../utils/styles"

export const Nav = styled.nav``
export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`
export const ListItem = styled.li`
  display: inline-block;
  height: ${props => rem(`${props.size ?? 26}px`)};
  width: ${props => rem(`${props.size ?? 26}px`)};

  & + & {
    margin-left: ${rem("30px")};
  }

  ${images(
    css`
      height: 100%;
      width: 100%;
    `,
  )}
`
export const Link = styled.a`
  color: ${props => props.color ?? props.theme.color.socialLinks};

  span {
    ${hideVisually()}
  }
`
