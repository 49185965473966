import styled from "styled-components"
import { rem, hideVisually } from "polished"

import TextInput from "./text-input"
import Button from "../buttons/button"

export const Group = styled.div`
  & + & {
    padding-top: ${rem("24px")};
  }
`
export const Label = styled.label`
  ${hideVisually()}
`
export const Email = styled(TextInput).attrs({
  type: "email",
  placeholder: "Your e-mail",
})``
export const Message = styled(TextInput).attrs({
  as: "textarea",
  placeholder: "Your message",
})`
  min-height: ${rem("188px")};
  height: 100%;
`
export const SendButton = styled(Button).attrs({ children: "send message" })``
export const Form = styled.form`
  display: flex;
  flex-direction: column;

  ${Group} {
    &:nth-child(2) {
      flex-grow: 2;
    }
  }
`
