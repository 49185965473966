import React from "react"
import PropTypes from "prop-types"

import { Wrapper, Card, Icon, Content } from "./icon-card.style"

IconCard.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node.isRequired,
  color: PropTypes.string,
  shadowColor: PropTypes.string,
  iconColorPrimary: PropTypes.string,
  iconColorSecondary: PropTypes.string,
  as: PropTypes.string,
}

export default function IconCard({
  children,
  icon,
  color,
  shadowColor,
  iconColorPrimary,
  iconColorSecondary,
  ...props
}) {
  return (
    <Wrapper {...props}>
      <Card {...{ color, shadowColor }}>
        <Icon
          colorPrimary={iconColorPrimary}
          colorSecondary={iconColorSecondary}
        >
          {icon}
        </Icon>
        <Content>{children}</Content>
      </Card>
    </Wrapper>
  )
}

export * from "./icon-card.style"
