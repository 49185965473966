import React from "react"
import PropTypes from "prop-types"

import { Wrapper, Icon } from "./icon-button.style"
import { Variant } from "./button"

IconButton.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  size: PropTypes.number,
}

export default function IconButton({
  children,
  loading = false,
  size = 54,
  ...props
}) {
  return (
    <Wrapper {...props} {...{ loading, size }}>
      <Icon {...{ children, loading }} />
    </Wrapper>
  )
}

export { Variant }
export * from "./icon-button.style"
