import styled, { css } from "styled-components"
import { cover, rem, hideVisually } from "polished"
import { Link as _Link } from "gatsby"

import { Size } from "./img-card"
import { cardBorders, images } from "../../utils/styles"
import _Card from "./card"

export const Wrapper = styled.article`
  ${props =>
    props.breakpoint === Size.LARGE &&
    css`
      max-width: ${props.featured ? rem("1100px") : rem("990px")};
    `}
`
export const InnerWrapper = styled.div`
  ${props =>
    props.featured &&
    props.breakpoint !== Size.SMALL &&
    css`
      padding-top: ${rem("15px")};
      padding-left: ${rem("50px")};
    `}
`
export const Card = styled(_Card)`
  ${props =>
    props.breakpoint === Size.LARGE &&
    css`
      display: flex;
    `}

  ${props =>
    props.featured &&
    css`
      position: relative;
    `}
`
export const ImageWrapper = styled.div`
  ${props =>
    props.breakpoint === Size.LARGE &&
    css`
      flex: 1;
      max-width: ${props.featured ? "460px" : "410px"};

      ${props.featured &&
      css`
        position: relative;
        left: 0;
        top: ${rem("-15px")};
        margin-left: ${rem("-50px")};
      `}
    `}
`
export const Image = styled.div`
  position: relative;
  height: 0;
  padding-top: 75%;

  & {
    max-width: 100%;
  }

  ${images(css`
    ${cover()}
    ${cardBorders(30)}

    height: 100%;
    max-width: 100%;
    user-select: none;
  `)}

  .gatsby-image-wrapper {
    ${cover()}

    position: absolute !important;
    user-select: none;
  }
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  ${props =>
    props.breakpoint === Size.SMALL &&
    css`
      padding-top: ${rem("24px")};
    `}

  ${props =>
    props.breakpoint === Size.LARGE &&
    css`
      flex: 1;
      padding: 0 ${props.featured ? 0 : rem("15px")} 0 ${rem("70px")};

      ${props.featured &&
      props.breakpoint !== Size.SMALL &&
      css`
        padding: ${rem("45px")} ${rem("60px")} ${rem("40px")} ${rem("45px")};
      `}
    `}
`
export const Link = styled(_Link)`
  text-decoration: none;
  color: currentColor;
`
export const ImageLink = styled(Link)`
  > span {
    ${hideVisually()}
  }
`
