import React from "react"
import PropTypes from "prop-types"

import {
  Wrapper,
  ScrollSpyNav,
  Nav,
  Heading,
  LogoLink,
  Logo,
  HamburgerWrapper,
  Hamburger,
} from "./main-nav.style"
import useResponsiveBreakpoints from "../hooks/use-responsive-breakpoints"

export const Size = {
  SMALL: "xs",
  LARGE: "lg",
  RESPONSIVE: "responsive",
}

MainNav.propTypes = {
  children: PropTypes.func.isRequired,
  size: PropTypes.oneOf(Object.values(Size)),
  scrollSpyProps: PropTypes.object,
  headingProps: PropTypes.object,
}

export default function MainNav({
  children,
  size,
  scrollSpyProps = {},
  headingProps,
  ...props
}) {
  const [isOpen, setOpen] = React.useState(false)
  const { ref, breakpoint } = useResponsiveBreakpoints(size, {
    [Size.SMALL]: 0,
    [Size.LARGE]: 1200,
  })

  children = children({ breakpoint, isOpen })

  const isScrollSpyUsed = Object.keys(scrollSpyProps).length > 0
  const markup = (
    <Nav ref={isScrollSpyUsed ? undefined : ref} {...props}>
      <Heading {...{ children: "Main navigation", ...headingProps }} />
      <LogoLink to="/">
        <Logo />
      </LogoLink>
      <HamburgerWrapper breakpoint={breakpoint}>
        <Hamburger label="Show menu" toggled={isOpen} toggle={setOpen} />
      </HamburgerWrapper>
      {children}
    </Nav>
  )

  if (!isScrollSpyUsed) {
    return markup
  }

  return (
    <Wrapper ref={ref}>
      <ScrollSpyNav {...scrollSpyProps}>{markup}</ScrollSpyNav>
    </Wrapper>
  )
}

export * from "./main-nav.style"
