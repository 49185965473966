import styled from "styled-components"
import { rem, hideVisually } from "polished"

import { Grid } from "../utils/styles"
import _Header, { MainNav, Sticky } from "../components/header"
import _Footer from "../components/footer"

export const Header = styled(_Header)`
  ${Sticky},
  & > div > div /* ScrollSpyNav */ {
    display: inherit;
    grid-template-columns: inherit;
    column-gap: inherit;
    grid-column: full;
  }

  ${MainNav} {
    padding: ${rem("10px")} 0;
    align-self: start;
  }
`
export const Main = styled.main`
  row-gap: ${rem("64px")};
`
export const Footer = styled(_Footer)`
  grid-column: full;
`
export const Wrapper = styled(Grid)`
  ${Header}, ${Main} {
    display: inherit;
    grid-template-columns: inherit;
    column-gap: inherit;
    grid-column: full;
  }
`
export const SkipToContent = styled.a.attrs({ children: "Skip to content" })`
  ${hideVisually()}
`
