import { createGlobalStyle, css } from "styled-components"
import { normalize, fluidRange, rem } from "polished"
import PropTypes from "prop-types"

import { Wrapper as ImgCard } from "./cards/img-card"
import { Wrapper as TeamMember } from "./cards/team-member"
import { Wrapper as IconCard } from "./cards/icon-card"
import Button from "./buttons/button"

const propTypes = {
  disableAnimations: PropTypes.bool,
}

const GlobalStyle = createGlobalStyle`
  ${normalize()}

  * {
    box-sizing: border-box;
  }

  html {
    /* FIXME: Can't use rem due to the bug inside fluidRange */
    ${props => {
      return fluidRange({
        prop: "font-size",
        fromSize: props.theme.font.sizeMin,
        toSize: props.theme.font.sizeMax,
      })
    }}
    scroll-behavior: smooth;
  }

  body {
    color: ${props => props.theme.color.text};
    font-family: ${props => props.theme.font.primary};
  }

  ${props =>
    !props.disableAnimations &&
    css`
      @media screen and (min-width: ${rem("1200px")}) {
        ${ImgCard},
        ${TeamMember},
        ${IconCard},
        ${Button} {
          transition: transform 0.25s ease-in-out;

          &:hover {
            transform: scale(1.05);
          }
        }
      }
    `}
`

GlobalStyle.propTypes = propTypes

export default GlobalStyle
