import useDimensions from "react-cool-dimensions"

export default function useResponsiveBreakpoints(
  size,
  breakpoints,
  options = {},
) {
  const { currentBreakpoint, ...rest } = useDimensions({
    breakpoints,
    ...options,
  })
  const breakpoint =
    (size !== "responsive" ? size : currentBreakpoint) ?? currentBreakpoint

  return { ...rest, currentBreakpoint, breakpoint }
}
