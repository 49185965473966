import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { ThemeProvider } from "styled-components"
import { Helmet } from "react-helmet"
import "typeface-raleway"

import * as theme from "../../config/theme"
import GlobalStyle from "../components/global.style"
import { Wrapper, SkipToContent, Header, Main, Footer } from "./layout.style"

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  mainNavProps: PropTypes.shape({
    scrollSpyProps: PropTypes.object,
    children: PropTypes.func,
  }),
  contactFormFields: PropTypes.node,
}

export default function Layout({
  children,
  mainNavProps,
  contactFormFields,
  ...props
}) {
  const data = useStaticQuery(graphql`
    query {
      privacyPolicy: file(
        sourceInstanceName: { eq: "files" }
        name: { eq: "privacy-policy" }
      ) {
        publicURL
      }
      site {
        siteMetadata {
          title
          description
          author
          twitterUrl
          linkedinUrl
          facebookUrl
          githubUrl
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Helmet bodyAttributes={{ id: "top" }} />
      <Wrapper {...props}>
        <SkipToContent href="#content" />
        <Header mainNavProps={mainNavProps} />
        <Main id="content">{children}</Main>
        <Footer
          privacyPolicyUrl={data.privacyPolicy.publicURL}
          socialLinks={_getSocialLinks(data.site.siteMetadata)}
          contactFormFields={contactFormFields}
        />
      </Wrapper>
    </ThemeProvider>
  )
}

function _getSocialLinks(siteMetadata) {
  return Object.entries(siteMetadata).reduce((acc, [key, url]) => {
    if (!key.endsWith("Url")) {
      return acc
    }

    const [type] = key.split("Url")
    acc.push({ type, url })

    return acc
  }, [])
}

export * from "./layout.style"
