import React from "react"
import PropTypes from "prop-types"
import { toast } from "react-toastify"
import { useForm } from "react-hook-form"
import { useLocation } from "@reach/router"

import { Form, Group, Label, Email, Message, SendButton } from "./contact.style"
import { ToastContainer } from "./newsletter.style"

ContactForm.propTypes = {
  additionalFields: PropTypes.node,
}

export default function ContactForm({ additionalFields, ...props }) {
  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm()
  const location = useLocation()

  React.useEffect(() => {
    if (isSubmitting) {
      if (errors.email) {
        toast(errors.email.message, {
          position: "bottom-right",
          type: "warning",
        })
      }

      if (errors.message) {
        toast(errors.message.message, {
          position: "bottom-right",
          type: "warning",
        })
      }
    }
  }, [errors.email, errors.message, isSubmitting])

  return (
    <>
      <Form
        noValidate
        name="contact"
        method="POST"
        data-netlify="true"
        action="/success"
        onSubmit={handleSubmit((_, event) => {
          try {
            event.nativeEvent.target.submit()
          } catch (err) {
            console.error(err)
            toast("Something goes wrong", {
              position: "bottom-right",
              type: "error",
            })
          }
        })}
        {...props}
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        <input type="hidden" name="path" value={location.pathname} />
        {additionalFields}
        <Group>
          <Label htmlFor="email">Your e-mail:</Label>
          <Email
            id="email"
            name="email"
            aria-invalid={errors.email ? "true" : "false"}
            hasError={errors.email ? true : false}
            ref={register({
              required: "E-mail is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid e-mail address",
              },
            })}
          />
        </Group>
        <Group>
          <Label htmlFor="message">Your message:</Label>
          <Message
            id="message"
            name="message"
            aria-invalid={errors.message ? "true" : "false"}
            hasError={errors.message ? true : false}
            ref={register({
              required: "Message is required",
            })}
          />
        </Group>
        <Group>
          <SendButton />
        </Group>
      </Form>
      <ToastContainer />
    </>
  )
}

export * from "./contact.style"
