import React from "react"
import PropTypes from "prop-types"

import { Wrapper } from "./icon.style"

Icon.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.number,
}

export default function Icon({ size = 70, ...props }) {
  return <Wrapper size={size} {...props} />
}

export * from "./icon.style"
