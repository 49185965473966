import React from "react"
import PropTypes from "prop-types"
import { FaMapMarkerAlt } from "react-icons/fa"
import { IoMdMail } from "react-icons/io"

import {
  Wrapper,
  Heading,
  Contact,
  ContactForm,
  Nav,
  NavGroup,
  NavGroupHeading,
  NavList,
  NavItem,
  NavLink,
  Copy,
  Divider,
  ContactTitle,
  ContactSubtitle,
  Address,
  SocialLinks,
  BackToTopBtn,
} from "./footer.style"

Footer.propTypes = {
  socialLinks: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      size: PropTypes.number,
      color: PropTypes.string,
    }).isRequired,
  ).isRequired,
  privacyPolicyUrl: PropTypes.string,
  contactFormFields: PropTypes.node,
}

export default function Footer({
  privacyPolicyUrl,
  socialLinks,
  contactFormFields,
  ...props
}) {
  return (
    <Wrapper {...props}>
      <Heading>Page Footer</Heading>
      <Contact>
        <ContactTitle id="contact">contact</ContactTitle>
        <ContactSubtitle>let's talk about your project</ContactSubtitle>
        <Address>
          <p>
            <FaMapMarkerAlt />
            <span>
              ul. Radzikowskiego 100c/5
              <br />
              31-315 Kraków
            </span>
          </p>
          <p>
            <IoMdMail />
            <span>info@cometari.com</span>
          </p>
        </Address>
        <ContactForm additionalFields={contactFormFields} />
      </Contact>
      <Divider style={{ gridRow: 2 }} />
      <Nav>
        <NavGroup>
          <NavGroupHeading>Company</NavGroupHeading>
          <NavList>
            <NavItem>
              <NavLink to="/#team">About us</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="#">Specializations</NavLink>
            </NavItem>
            {privacyPolicyUrl?.length > 0 && (
              <NavItem>
                <NavLink as="a" href={privacyPolicyUrl} target="_blank">
                  Privacy policy
                </NavLink>
              </NavItem>
            )}
            <NavItem>
              <NavLink to="#">Contact us</NavLink>
            </NavItem>
          </NavList>
        </NavGroup>
        <NavGroup>
          <NavGroupHeading>Services</NavGroupHeading>
          <NavList>
            <NavItem>
              <NavLink to="#">DevOps</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="#">
                Observability and
                <br />
                Log Analysis
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="#">Cloud Services</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/travel-services">Travel Tech</NavLink>
            </NavItem>
          </NavList>
        </NavGroup>
        <NavGroup>
          <NavGroupHeading>Projects</NavGroupHeading>
          <NavList>
            <NavItem>
              <NavLink to="/case-studies">Case studies</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/videos">Videos</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/workshops">Workshops</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="#">Testimonials</NavLink>
            </NavItem>
          </NavList>
        </NavGroup>
        <NavGroup>
          <NavGroupHeading>Follow us</NavGroupHeading>
          <SocialLinks links={socialLinks} />
        </NavGroup>
      </Nav>
      <BackToTopBtn />
      <Copy>
        {new Date().getFullYear()} &copy; Cometari All rigths reserved.
      </Copy>
    </Wrapper>
  )
}

export * from "./footer.style"
