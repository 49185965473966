import React from "react"
import PropTypes from "prop-types"
import {
  FaLinkedin,
  FaTwitterSquare,
  FaGithubSquare,
  FaExternalLinkSquareAlt,
  FaFacebookSquare,
} from "react-icons/fa"

import { Nav, List, ListItem, Link } from "./social.style"

SocialLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      size: PropTypes.number,
      color: PropTypes.string,
    }).isRequired,
  ).isRequired,
}

export default function SocialLinks({ links, size, color, ...props }) {
  if (typeof links.length !== "number" || links.length < 1) {
    return null
  }

  return (
    <Nav {...props}>
      <List>
        {links.map(({ id, type, url }) => (
          <ListItem key={id ?? url} size={size}>
            <Link key={id ?? url} href={url} color={color}>
              <span>{type}</span>
              {{
                linkedin: FaLinkedin,
                twitter: FaTwitterSquare,
                github: FaGithubSquare,
                facebook: FaFacebookSquare,
              }[type]?.() ?? <FaExternalLinkSquareAlt />}
            </Link>
          </ListItem>
        ))}
      </List>
    </Nav>
  )
}

export * from "./social.style"
