import styled, { css } from "styled-components"
import { rem } from "polished"

import Button from "./button"
import { circle, images } from "../../utils/styles"

export const Wrapper = styled(Button)`
  ${circle}

  display: flex;
  height: ${props => rem(`${props.size}px`)};
  width: ${props => rem(`${props.size}px`)};
  padding: 0;
  line-height: 1;
  position: relative;

  &::after {
    width: auto;
    margin-top: -0.25em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    font-size: 200%;
  }
`
export const Icon = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !["loading"].includes(prop) && defaultValidatorFn(prop),
})`
  margin: auto;
  height: 33%;
  width: 33%;
  display: ${props => (props.loading ? "none" : undefined)};

  ${images(css`
    height: 100%;
    width: 100%;
  `)}
`
