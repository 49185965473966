import styled, { css } from "styled-components"
import { rem, transparentize } from "polished"

import { circle, images, shadow } from "../utils/styles"

export const Wrapper = styled.div`
  display: flex;
  height: ${props => rem(`${props.size ?? 70}px`)};
  width: ${props => rem(`${props.size ?? 70}px`)};
  position: relative;
  background-color: ${props =>
    props.colorSecondary ?? transparentize(0.9, props.theme.color.iconPrimary)};

  &,
  &::before {
    ${circle};
  }

  &::before {
    ${props => shadow(props.shadowColor)}

    content: "";
    height: 80%;
    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    background-color: ${props =>
      props.colorPrimary ?? props.theme.color.iconSecondary};
  }

  ${images(css`
    margin: auto;
    height: 40%;
    width: 40%;
    z-index: 0;
    user-select: none;
  `)}
`
