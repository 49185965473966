import styled, { css } from "styled-components"
import { rem } from "polished"

export const rounded = size =>
  css`
    border-radius: ${rem(`${size ?? 20}px`)};
  `

export const cardBorders = size =>
  css`
    ${rounded(size)}
    border-bottom-left-radius: 0;
  `

export const shadow = color => css`
  box-shadow: 0 ${rem("30px")} ${rem("40px")} 0
    ${props => color ?? props.theme.color.shadow};
`
export const circle = css`
  border-radius: 50%;
`
export const images = rules => css`
  img,
  svg,
  object,
  embed,
  .gatsby-image-wrapper {
    ${rules}
  }
`
export const grid = () => css`
  --gap: 10px;
  --min-padding: 10px;
  --max-padding: 1fr;
  --columns: 5;
  --gaps: calc(var(--columns) - 1);
  --max-content-width: calc(1260px - var(--gaps) * var(--gap));

  display: grid;
  column-gap: var(--gap);
  grid-template-columns:
    [full-start] minmax(var(--min-padding), var(--max-padding))
    [main-start] repeat(
      var(--columns),
      minmax(0, calc(var(--max-content-width) / var(--columns)))
    )
    [main-end] minmax(var(--min-padding), var(--max-padding)) [full-end];

  @media screen and (min-width: ${rem("900px")}) {
    --columns: 12;
    --gap: 20px;
  }
`
export const Grid = styled.div`
  ${grid()}
`
