import React from "react"
import PropTypes from "prop-types"

import {
  Wrapper,
  InnerWrapper,
  Card,
  ImageWrapper,
  Image,
  Content,
  ImageLink,
} from "./img-card.style"
import useResponsiveBreakpoints from "../../hooks/use-responsive-breakpoints"

export const Size = {
  SMALL: "xs",
  LARGE: "lg",
  RESPONSIVE: "responsive",
}

ImgCard.propTypes = {
  children: PropTypes.func.isRequired,
  image: PropTypes.node,
  size: PropTypes.oneOf(Object.values(Size)),
  featured: PropTypes.bool,
  url: PropTypes.string,
}

export default function ImgCard({
  children,
  image,
  size,
  featured,
  url,
  ...props
}) {
  const { ref, breakpoint } = useResponsiveBreakpoints(size, {
    [Size.SMALL]: 0,
    [Size.LARGE]: 800,
  })
  const shouldHaveFeaturedLook = featured && breakpoint !== Size.SMALL

  return (
    <Wrapper ref={ref} featured={featured} breakpoint={breakpoint} {...props}>
      <InnerWrapper featured={featured} breakpoint={breakpoint}>
        <Card
          breakpoint={breakpoint}
          shadowColor={shouldHaveFeaturedLook ? undefined : "transparent"}
          color={shouldHaveFeaturedLook ? "#fff" : undefined}
        >
          {image && (
            <ImageWrapper breakpoint={breakpoint} featured={featured}>
              <Image>
                {url ? (
                  <ImageLink to={url}>
                    <span>Show more</span> {image}
                  </ImageLink>
                ) : (
                  image
                )}
              </Image>
            </ImageWrapper>
          )}
          <Content breakpoint={breakpoint} featured={featured}>
            {children(breakpoint)}
          </Content>
        </Card>
      </InnerWrapper>
    </Wrapper>
  )
}

export * from "./img-card.style"
