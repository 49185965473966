import React from "react"
import PropTypes from "prop-types"

import {
  Wrapper,
  Sticky,
  MainNav,
  Menu,
  MenuItem,
  Link,
  ContactLink,
  PageHeading,
} from "./header.style"

Header.propTypes = {
  mainNavProps: PropTypes.shape({
    scrollSpyProps: PropTypes.object,
    children: PropTypes.func.isRequired,
  }),
}

export default function Header({ mainNavProps, ...props }) {
  const [isFixed, setIsFixed] = React.useState(false)

  return (
    <Wrapper {...props}>
      <PageHeading>Cometari</PageHeading>
      <Sticky
        onFixedToggle={state => {
          setIsFixed(state)
        }}
      >
        <MainNav
          headingProps={{ as: "h2" }}
          isFixed={isFixed}
          children={mainNavRenderer}
          {...mainNavProps}
        />
      </Sticky>
    </Wrapper>
  )
}

const mainNavRenderer = ({ breakpoint, isOpen }) => (
  <>
    <Menu breakpoint={breakpoint} isOpen={isOpen}>
      <MenuItem>
        <Link to="/#team">about us</Link>
      </MenuItem>
      <MenuItem>
        <Link to="/#specializations">specializations</Link>
      </MenuItem>
      <MenuItem>
        <Link to="/case-studies">case studies</Link>
      </MenuItem>
      <MenuItem>
        <Link to="/videos#content">videos</Link>
      </MenuItem>
      <MenuItem>
        <Link to="/workshops#content">workshops</Link>
      </MenuItem>
      <MenuItem>
        <Link to="/travel-services/#content">travel services</Link>
      </MenuItem>
    </Menu>
    <ContactLink href="#contact" breakpoint={breakpoint} isOpen={isOpen} />
  </>
)

export * from "./header.style"
