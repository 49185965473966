import { transparentize, darken } from "polished"

export const primary = "#7389ff"
export const secondary = "#ff8389"

export const dark = "#2b303d"
export const light = "#fff"

export const bgLight = "#fff"
export const bgDark = "#17213c"

export const text = dark
export const textLight = light
export const shadow = transparentize(0.9, primary)
export const link = primary
export const category = text
export const tags = text
export const iconPrimary = primary
export const iconSecondary = light
export const teamMemberPosition = secondary
export const socialLinks = primary

export const toastSuccess = darken(0.4, "#55ffb3")
export const toastWarning = "ffd455"
export const toastError = secondary

export const btnPrimary = primary
export const btnSecondary = light
export const btnPrimaryText = light
export const btnSecondaryText = primary

export const headerBg = light

export const footerBg = "#17213c"
export const footerText = transparentize(0.5, light)
export const footerContactIcons = secondary
export const footerTitles = light
