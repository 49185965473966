import styled, { css } from "styled-components"
import { rem } from "polished"
import PropTypes from "prop-types"

import { dottedContent } from "../../utils/animations"

export const Variant = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
}

const propTypes = {
  variant: PropTypes.oneOf(Object.values(Variant)),
  loading: PropTypes.bool,
}

const Button = styled.button.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !["loading"].includes(prop) && defaultValidatorFn(prop),
})`
  width: ${props => (props.fill ? "100%" : undefined)};
  padding: 0 ${rem("36px")};
  margin: 0;
  border-style: solid;
  border-width: 1px;
  border-radius: ${rem("12px")};
  font-size: ${rem("16px")};
  font-weight: 500;
  line-height: 2.75;
  text-decoration: none;
  text-align: center;

  &:focus {
    outline: 2px dotted ${props => props.theme.color.primary};
  }

  &::after {
    display: inline-block;
    width: 0;
    content: "";
    animation: ${props =>
      props.loading &&
      css`
        ${dottedContent} steps(1, end) 1s infinite
      `};
  }

  ${props =>
    props.variant !== Variant.SECONDARY &&
    css`
      background-color: ${props.theme.color.btnPrimary};
      color: ${props.theme.color.btnPrimaryText};
      border-color: ${props.theme.color.btnPrimary};
    `}

  ${props =>
    props.variant === Variant.SECONDARY &&
    css`
      background-color: ${props.theme.color.btnSecondary};
      border-color: ${props.theme.color.btnSecondaryText};
      color: ${props.theme.color.btnSecondaryText};
    `}
`

Button.propTypes = propTypes

export default Button
