import styled from "styled-components"
import { rem, padding } from "polished"

import _Card from "./card"
import _Icon from "../icon"

export const Wrapper = styled.article`
  padding-top: 50px;
`
export const Card = styled(_Card)`
  height: 100%;
`
export const Icon = styled(_Icon)`
  height: ${rem("100px")};
  width: ${rem("100px")};
  margin-left: 20px;
  margin-top: -50px;
`
export const Content = styled.div`
  ${padding(null, rem("30px"), rem("30px"), rem("30px"))}
`
