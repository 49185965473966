import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { rem, transparentize, fluidRange, hideVisually } from "polished"
import { IoIosArrowUp } from "react-icons/io"

import { Grid } from "../utils/styles"
import _ContactForm, { Group } from "./forms/contact"
import IconButton from "./buttons/icon-button"
import Badge from "./badge"
import footerImg from "../images/backgrounds/footer.svg"

export { default as SocialLinks } from "./links/social"

export const Heading = styled.h2`
  ${hideVisually()}
`
export const ContactTitle = styled(Badge)`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const ContactSubtitle = styled.p`
  font-weight: 500;
  color: ${props => props.theme.color.footerTitles};

  ${fluidRange(
    {
      prop: "fontSize",
      fromSize: "24px",
      toSize: "42px",
    },
    "400px",
    "1000px",
  )}
`
export const Address = styled.address`
  font-weight: 500;

  > p {
    display: flex;

    > svg {
      color: ${props => props.theme.color.footerContactIcons};
    }

    > span {
      margin-left: ${rem("10px")};
    }
  }

  p + p {
    margin-top: ${rem("36px")};
  }
`
export const ContactForm = styled(_ContactForm)`
  ${Group}:last-of-type {
    text-align: center;

    @media screen and (min-width: ${rem("1200px")}) {
      text-align: left;
    }
  }
`
export const Contact = styled.div`
  display: inherit;
  grid-template-columns: inherit;
  column-gap: inherit;
  grid-column: full;

  ${ContactTitle} {
    grid-column: 2 / span 2;
    max-width: ${rem("120px")};
  }

  ${ContactSubtitle}, ${Address}, ${ContactForm} {
    grid-column: main;
  }

  ${Address} {
    order: 1;
  }

  ${ContactForm} {
    padding-bottom: ${rem("64px")};
    padding-top: ${rem("64px")};
  }

  @media screen and (min-width: ${rem("1200px")}) {
    ${Address} {
      order: unset;
    }

    ${ContactTitle} {
      grid-column: 3 / span 2;
    }

    ${ContactSubtitle},
    ${Address} {
      grid-column: 3 / span 3;
    }

    ${ContactForm} {
      grid-column: 7 / span 6;
      grid-row: 1 / span 3;
      padding-bottom: 0;
      padding-top: 0;
    }
  }
`
export const Divider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-height: 1px;

  @media (min-width: ${rem("1200px")}) {
    position: relative;
  }

  &::before {
    content: "";
    height: 1px;
    box-sizing: border-box;
    width: 100%;
    position: absolute;
    background-color: ${transparentize(0.7, "#fff")};
  }
`
export const Nav = styled.nav`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: ${rem("15px")};
`
export const NavGroup = styled.div`
  padding-bottom: ${rem("64px")};
`
export const NavGroupHeading = styled.h3`
  color: ${props => props.theme.color.footerTitles};
  font-size: ${rem("24px")};
  font-weight: 500;
  letter-spacing: ${rem("-0.39px")};
`
export const NavList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`
export const NavItem = styled.li`
  & + & {
    margin-top: ${rem("13px")};
  }
`
export const NavLink = styled(Link)`
  color: currentColor;
  text-decoration: none;
  font-size: ${rem("16px")};
  font-weight: 500;
`
export const Copy = styled.div`
  font-size: ${rem("16px")};
  font-weight: 500;
  text-align: center;
`
export const Wrapper = styled(Grid).attrs({ as: "footer" })`
  background-color: ${props => props.theme.color.footerBg};
  color: ${props => props.theme.color.footerText};
  row-gap: ${rem("50px")};
  padding-bottom: ${rem("35px")};
  z-index: 0;

  ${fluidRange(
    {
      prop: "paddingTop",
      fromSize: "65px",
      toSize: "128px",
    },
    "400px",
    "1200px",
  )}

  ${Divider}, ${Nav}, ${Copy} {
    grid-column: main;
  }

  @media screen and (min-width: ${rem("1200px")}) {
    &::before {
      content: "";
      grid-column: 3 / span 7;
      grid-row: 1 / 4;
      color: #fff;
      text-align: center;
      background-image: ${`url(${footerImg})`};
      background-repeat: no-repeat;
      background-position: right top;
      background-size: contain;
      position: relative;
      left: -47%;
      top: -50px;
      z-index: -1;
    }

    ${Contact} {
      grid-row: 1;
    }

    ${Nav} {
      grid-column: 4 / span 8;
      grid-row: 3;
    }

    ${Divider} {
      grid-column: 3 / span 10;
    }
  }
`

const HiddenText = styled.span`
  ${hideVisually()};
`
export const BackToTopBtn = props => (
  <Divider>
    <Link to="#top">
      <IconButton as="div" {...props}>
        <HiddenText>Back to the top</HiddenText>
        <IoIosArrowUp />
      </IconButton>
    </Link>
  </Divider>
)
