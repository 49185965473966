import styled, { css } from "styled-components"
import { rem } from "polished"

import { circle, images } from "../../utils/styles"
import { Size } from "./team-member"
import _Card from "./card"

export const Wrapper = styled.article``
export const Card = styled(_Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${rem("30px")} ${rem("15px")} ${rem("66px")};

  ${props =>
    props.breakpoint === Size.LARGE &&
    css`
      flex-direction: row;
      justify-content: center;
      padding: ${rem("48px")} ${rem("87px")};
    `}
`
export const Photo = styled.div`
  height: ${rem("136px")};
  width: ${rem("136px")};
  flex-shrink: 0;

  ${images(css`
    ${circle}

    height: 100%;
    width: 100%;
    user-select: none;
  `)}
`
export const Content = styled.div`
  text-align: center;

  ${props =>
    props.breakpoint === Size.LARGE &&
    css`
      text-align: left;
      margin-left: ${rem("77px")};
    `}
`
export const Header = styled.header`
  padding: ${rem("26px")} 0;

  ${props =>
    props.breakpoint === Size.LARGE &&
    css`
      padding-top: 0;
    `}
`
export const Name = styled.h3`
  margin: ${rem("5px")} 0;
  font-size: ${rem("24px")};
  font-weight: 500;
  letter-spacing: -0.39px;
`
export const Position = styled.div`
  font-size: ${rem("16px")};
  font-weight: 500;
  color: ${props => props.theme.color.teamMemberPosition};
`
export const Bio = styled.div`
  font-size: ${rem("16px")};
  font-weight: 300;
  line-height: 1.5;
`
export const Footer = styled.footer`
  margin-top: ${rem("20px")};
`
