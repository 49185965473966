import React from "react"
import PropTypes from "prop-types"

import {
  Wrapper,
  Card,
  Photo,
  Content,
  Header,
  Name,
  Position,
  Bio,
  Footer,
} from "./team-member.style"
import SocialLinks from "../links/social"
import useResponsiveBreakpoints from "../../hooks/use-responsive-breakpoints"

export const Size = {
  SMALL: "xs",
  LARGE: "lg",
  RESPONSIVE: "responsive",
}

TeamMember.propTypes = {
  photo: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  bio: PropTypes.string.isRequired,
  size: PropTypes.number,
  socialLinks: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      size: PropTypes.number,
      color: PropTypes.string,
    }).isRequired,
  ),
  cardProps: PropTypes.shape({
    shadowColor: PropTypes.string,
    color: PropTypes.string,
  }),
  nameProps: PropTypes.object,
}

export default function TeamMember({
  photo,
  name,
  position,
  bio,
  size,
  socialLinks = [],
  cardProps,
  nameProps,
  ...props
}) {
  const { ref, breakpoint } = useResponsiveBreakpoints(size, {
    [Size.SMALL]: 0,
    [Size.LARGE]: 600,
  })

  return (
    <Wrapper {...props} ref={ref} breakpoint={breakpoint}>
      <Card breakpoint={breakpoint} {...cardProps}>
        <Photo breakpoint={breakpoint}>{photo}</Photo>
        <Content breakpoint={breakpoint}>
          <Header breakpoint={breakpoint}>
            <Name {...nameProps}>{name}</Name>
            <Position>{position}</Position>
          </Header>
          <Bio>{bio}</Bio>
          <Footer>
            <SocialLinks links={socialLinks} />
          </Footer>
        </Content>
      </Card>
    </Wrapper>
  )
}

export * from "./team-member.style"
