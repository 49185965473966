import React from "react"
import PropTypes from "prop-types"
import { useForm } from "react-hook-form"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { toast } from "react-toastify"

import {
  Wrapper,
  Label,
  Email,
  SendButton,
  ToastContainer,
} from "./newsletter.style"
import useResponsiveBreakpoints from "../../hooks/use-responsive-breakpoints"
import meta from "../../../package.json"

export const Size = {
  SMALL: "xs",
  LARGE: "lg",
  RESPONSIVE: "responsive",
}

NewsletterForm.propTypes = {
  size: PropTypes.oneOf(Object.values(Size)),
}

export default function NewsletterForm({ size, ...props }) {
  const { ref, breakpoint } = useResponsiveBreakpoints(size, {
    [Size.SMALL]: 0,
    [Size.LARGE]: 480,
  })
  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm()

  const onSubmit = React.useCallback(async ({ email }, event) => {
    event.preventDefault()

    try {
      const { result, msg } = await addToMailchimp(email, {
        FROM: meta.name,
      })
      toast(msg, {
        position: "bottom-right",
        type: result === "success" ? "success" : "error",
      })
    } catch (err) {
      toast(err.msg, {
        position: "bottom-right",
        type: "error",
      })
    }
  }, [])

  React.useEffect(() => {
    if (isSubmitting && errors.email) {
      toast(errors.email.message, {
        position: "bottom-right",
        type: "warning",
      })
    }
  }, [errors.email, isSubmitting])

  return (
    <>
      <Wrapper
        noValidate
        ref={ref}
        breakpoint={breakpoint}
        onSubmit={handleSubmit(onSubmit)}
        {...props}
      >
        <Label htmlFor="newsletter-email">E-mail:</Label>
        <Email
          name="email"
          id="newsletter-email"
          aria-invalid={errors.email ? "true" : "false"}
          hasError={errors.email ? true : false}
          breakpoint={breakpoint}
          ref={register({
            required: "E-mail is required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid e-mail address",
            },
          })}
        />
        <SendButton loading={isSubmitting} />
      </Wrapper>
      <ToastContainer />
    </>
  )
}

export * from "./newsletter.style"
