import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  creator: PropTypes.string,
  image: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
}

export default function SEO({
  title,
  creator,
  image,
  description = "",
  lang = "en",
  meta = [],
}) {
  const {
    site: { siteMetadata },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `,
  )
  const metaDescription = description || siteMetadata.description
  const metaImage = image || siteMetadata.image?.publicURL
  let _meta = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: creator || siteMetadata.author,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    {
      name: `twitter:site`,
      content: siteMetadata.author,
    },
  ]

  if (metaImage?.length > 0 && siteMetadata.siteUrl?.length > 0) {
    const imageUrl = String(new URL(metaImage, siteMetadata.siteUrl))

    _meta = _meta.concat([
      {
        property: `image`,
        content: imageUrl,
      },
      {
        property: `og:image`,
        content: imageUrl,
      },
      {
        name: `twitter:image`,
        content: imageUrl,
      },
    ])
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${siteMetadata.title}`}
      meta={_meta.concat(meta)}
    />
  )
}
