import styled, { css } from "styled-components"
import { rem, hideVisually, cover } from "polished"
import { ToastContainer as _ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import { Size } from "./newsletter"
import Card from "../cards/card"
import TextInput from "./text-input"
import Button from "../buttons/button"
import { cardBorders } from "../../utils/styles"

export const Wrapper = styled(Card).attrs(props => ({
  as: "form",
  color: "#fff",
  shadowColor: props.breakpoint === Size.SMALL ? "transparent" : undefined,
}))`
  ${cardBorders(20)}

  position: relative;
  text-align: center;

  ${props =>
    props.breakpoint !== Size.SMALL &&
    css`
      display: flex;
      padding: ${rem("7px")};
      padding-left: 0;
      text-align: left;
    `}
`
export const Label = styled.label`
  ${hideVisually()}
`
export const Email = styled(TextInput).attrs(props => ({
  type: "email",
  placeholder: "Your e-mail",
  shadow: props.breakpoint === Size.SMALL,
}))`
  ${cardBorders(20)}

  margin-bottom: ${props => props.breakpoint === Size.SMALL && rem("24px")};
  background-color: transparent;
  font-family: ${props => props.theme.font.secondary};

  ${props =>
    props.breakpoint !== Size.SMALL &&
    css`
      ${cover()}
      z-index: 0;
    `}
`
export const SendButton = styled(Button).attrs({ children: "send" })`
  width: 194px;
  flex-shrink: 0;

  ${props =>
    props.breakpoint !== Size.SMALL &&
    css`
      margin-left: auto;
      position: relative;
      z-index: 1;
    `}
`
export const ToastContainer = styled(_ToastContainer)`
  .Toastify__toast {
    &--success {
      background-color: ${props => props.theme.color.toastSuccess};
    }
    &--warning {
      background-color: ${props => props.theme.color.toastWarning};
    }
    &--error {
      background-color: ${props => props.theme.color.toastError};
    }
  }
`
