import styled from "styled-components"

import { cardBorders, shadow } from "../../utils/styles"
import { rem } from "polished"

const TextInput = styled.input
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
      !["fill"].includes(prop) && defaultValidatorFn(prop),
  })
  .attrs(props => ({ fill: props.fill ?? true }))`
  ${cardBorders(20)}
  ${props => props.shadow && shadow(props.shadowColor)}

  width: ${props => (props.fill ? "100%" : undefined)};
  padding: ${rem("20px")} ${rem("40px")};
  border: 0;
  font-size: ${rem("16px")};
  font-weight: 500;
  outline: 0;

  &:focus {
    box-shadow: inset 0px 0px 0px 2px ${props =>
      props.hasError ? props.theme.color.secondary : props.theme.color.primary};
  }

  &::placeholder {
    opacity: 0.4;
  }
`

export default TextInput
