import styled, { css } from "styled-components"
import { hideVisually } from "polished"
import _Sticky from "react-sticky-el"

import _MainNav from "./main-nav"
import { shadow } from "../utils/styles"

export { Menu, MenuItem, Link, ContactLink } from "./main-nav"

export const Wrapper = styled.header`
  z-index: 1000;
`
export const PageHeading = styled.h1`
  ${hideVisually()}
`
export const MainNav = styled(_MainNav)`
  overflow: hidden;

  &::before {
    content: "";
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    position: absolute;
    z-index: -1;
    transition-duration: 0.15s;
    transition-timing-function: ease-in-out;
    transition-property: background, box-shadow;

    ${props =>
      props.isFixed &&
      css`
        ${shadow()}

        background-color: ${props.theme.color.headerBg};
      `}
  }
`
export const Sticky = styled(_Sticky)``
