import styled from "styled-components"
import { rem } from "polished"
import PropTypes from "prop-types"

import { cardBorders } from "../utils/styles"

const propTypes = {
  color: PropTypes.string,
  textColor: PropTypes.string,
}

const Badge = styled.h3`
  ${cardBorders(7)}

  display: inline-block;
  padding: 0 ${rem("25px")};
  margin: 0;
  background-color: ${props => props.color ?? props.theme.color.secondary};
  color: ${props => props.textColor ?? props.theme.color.textLight};
  font-size: ${rem("16px")};
  font-weight: 500;
  line-height: 1.75;
  text-align: center;
`

Badge.propTypes = propTypes

export default Badge
